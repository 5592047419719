<template style="width: auto">
  <form
    action=""
    @submit.prevent="validateBeforeSubmit"
  >
    <card
      internal-card="modal-card card modal-material-card modal-card-form-container"
      url-help=""
    >
      <div
        slot="header"
        class="modal-card-header"
      >
        <p>Change password</p>
      </div>
      <section
        slot="body"
        class="modal-card-body"
      >
        <b-field
          :type="errors.has('originalPassword') ? 'is-danger':''"
          label="Old password"
        >
          <b-input
            v-model="originalPassword"
            v-validate="'required'"
            name="originalPassword"
            type="password"
            :password-reveal="originalPassword != '' && originalPassword != undefined"
            autocomplete="current-password"
          />
        </b-field>
        <span
          v-show="errors.has('originalPassword')"
          class="help is-danger"
        >{{ errors.first('originalPassword') }}</span>
        <b-field
          :type="errors.has('newPassword') ? 'is-danger':''"
          label="New password"
        >
          <b-input
            v-model="newPassword"
            v-validate="'required|min:6'"
            name="newPassword"
            type="password"
            :password-reveal="newPassword != '' && newPassword != undefined"
            autocomplete="new-password"
          />
        </b-field>
        <span
          v-show="errors.has('newPassword')"
          class="help is-danger"
        >{{ errors.first('newPassword') }}</span>
        <b-field
          :type="errors.has('confirmNewPassword') ? 'is-danger':''"
          label="Confirm new password"
        >
          <b-input
            v-model="confirmNewPassword"
            v-validate="{is: newPassword, required: true}"
            name="confirmNewPassword"
            type="password"
            :password-reveal="confirmNewPassword != '' && confirmNewPassword != undefined"
            autocomplete="new-password"
          />
        </b-field>
        <span
          v-show="errors.has('confirmNewPassword')"
          class="help is-danger"
        >{{ errors.first('confirmNewPassword') }}</span>
      </section>
      <footer
        slot="footer"
        class="card-footer has-text-centered modal-card-footer"
      >
        <button
          class="button force-right"
          type="button"
          @click="$parent.close()"
        >
          Cancel
        </button>
        <button
          :disabled="(!originalPassword || !newPassword || !confirmNewPassword
            || errors.has('originalPassword') || errors.has('confirmNewPassword')
            || errors.has('newPassword'))"
          :class="{'is-loading': isLoading }"
          class="button is-primary "
          type="submit"
        >
          Change password
        </button>
      </footer>
    </card>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import snackBarMessage from '@/helpers/snackBarMessage';
import card from '@/components/cross/Card.vue';
import ErrorMixin from '@/mixins/error';

export default {
  name: 'ChangeUserPassword',
  components: {
    card,
  },
  mixins: [ErrorMixin],
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const dict = {
      custom: {
        originalPassword: {
          required: 'The current password field is empty.',
        },
        newPassword: {
          required: 'The new password field is empty.',
          min: 'The password must be at least 6 characters.',
        },
        confirmNewPassword: {
          required: 'The confirm new password field is empty.',
          is: 'The passwords does not match.',
        },
      },
    };

    return {
      originalPassword: undefined,
      newPassword: undefined,
      confirmNewPassword: undefined,
      isLoading: false,
      dict,
    };
  },
  mounted() {
    this.$validator.localize('en', this.dict);
  },
  methods: {
    ...mapActions('user', { patchUser: 'patch' }),
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.changePassword();
        }
      }).catch((error) => { throw new Error(error); });
    },
    changePassword() {
      this.isLoading = true;
      this.patchUser([this.user.id, {
        password: this.newPassword,
        currentPassword: this.originalPassword,
      }]).then(() => {
        snackBarMessage.showSuccess('Password updated.');
        this.$parent.close();
      }).catch((err) => {
        snackBarMessage.showError(err.message);
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
.search-input {
  margin: 20px 10px 5px 10px;
}
.action-card-content {
  padding: 2%;
  margin: 4px 6px;
  /* text-align: center; */
  display: inline-block;
  width: 224px;
  height: 80px;
}
.device-info-title {
  margin-bottom: 0px !important;
  display: inline-flex;
  width: 100%;
  text-align: center;
}
.action-buttons-container {
  position: absolute;
  left: 0;
  bottom: 10%;
  right: 0;
}
.errorBanner {
  margin:0px;
  padding:8px;
}
</style>
