<template>
  <div class="card modal-material-card modal-card-small">
    <div class="modal-card-header">
      <p>
        {{ user.twoFactorEnabled ? 'Two-factor authentication' :
          'Enable Two-factor authentication' }}
      </p>
    </div>
    <section class="modal-card-body">
      <b-notification
        :active="error != undefined"
        :closable="false"
        class="errorBanner"
        type="is-danger"
      >
        {{ error != undefined ? error.message : '' }}
      </b-notification>
      <br v-show="error != undefined">
      <div v-show="manageCurrentOtp">
        <p>
          Manage your two-factor authentication method.
        </p>
        <br>
        <p
          class="centerVertically"
          style="margin-top:10px;"
        >
          <strong>Backup code:</strong>
          <a
            style="margin-left: 6px"
            @click="goToRegenerateRecoveryKey"
          >
            <button
              class="button is-small is-primary"
            >
              Regenerate
            </button>
          </a>
        </p>
        <p
          class="centerVertically"
          style="margin-top:10px;"
        >
          <strong>Disable Two-factor authentication: </strong>
          <a
            style="margin-left: 6px"
            @click="goToDisableOtp"
          >
            <button
              class="button is-small is-danger"
            >
              Disable
            </button>
          </a>
        </p>
      </div>
      <div v-show="requestStep || disableStep || regenerateRecoverKey">
        <div v-if="disableStep">
          <p>
            Are you sure you want to <strong>disable</strong> your two-factor authentication method?
          </p>
          <br>
          <p>
            Type your password to confirm:
          </p>
        </div>
        <div v-else-if="requestStep">
          <p>
            Increase your account security by using an Authenticator App to get
            two-factor authentication codes when prompted.
          </p>
          <br>
          <p>
            Type your password to continue:
          </p>
        </div>
        <div v-else-if="regenerateRecoverKey">
          <p>
            Regenerate the backup key to use if you get locked out of your account.
          </p>
          <br>
          <p>
            Type your password to confirm:
          </p>
        </div>
        <form @submit.prevent="validateBeforeSubmit">
          <b-field
            style="margin-top:6px;"
            :type="errors.has('password') ? 'is-danger':''"
          >
            <b-input
              id="password"
              v-model="password"
              v-validate="'required'"
              name="password"
              type="password"
              :password-reveal="password != ''"
            />
          </b-field>
          <span
            v-show="errors.has('password')"
            class="help is-danger"
          >{{ errors.first('password') }}</span>
        </form>
      </div>
      <div v-show="otpQrCode">
        <p>1.  Scan this barcode with your app</p>
        <center>
          <img
            style="width:100%;max-width: 240px;"
            :src="otpQrCode"
          >
        </center>
        <p>2. Enter the six-digit code from the application</p>
        <form @submit.prevent="validateBeforeSubmit">
          <b-field
            :type="errors.has('otpCode') ? 'is-danger':''"
          >
            <b-input
              id="otpCode"
              v-model="otpCode"
              v-validate="'required|length:6'"
              name="otpCode"
              type="number"
              placeholder="123456"
              autocomplete="off"
            />
          </b-field>
          <span
            v-show="errors.has('otpCode')"
            class="help is-danger"
          >{{ errors.first('otpCode') }}</span>
        </form>
      </div>
      <div v-show="recoveryKey">
        <b-notification
          :closable="false"
          class="errorBanner"
          type="is-success"
        >
          {{
            regeneratedRecoveryKey
              ? 'Backup key regenerated successfully' : 'Two-factor authentication enabled'
          }}
        </b-notification>
        <p style="margin: 12px 0px;">
          If you get locked out of your account, you can restore access with the following key
        </p>
        <b-field>
          <b-input
            id="recoveryKey"
            v-model="recoveryKey"
            name="recoveryKey"
            type="text"
            disabled
          />
        </b-field>
        <p>It is important to save it now and keep it in a safe place.</p>
      </div>
    </section>
    <footer class="card-footer has-text-centered modal-card-footer">
      <a
        class="button force-right"
        type="button"
        :disabled="isLoading"
        @click="goBack"
      >Cancel</a>
      <button
        v-if="requestStep || otpQrCode || disableStep || regenerateRecoverKey"
        type="submit"
        :class="{'is-loading': isLoading }"
        :disabled="isLoading"
        style="margin-left:6px"
        class="button is-primary"
        @click="validateBeforeSubmit"
      >
        <span v-if="otpQrCode">Enable</span>
        <span v-else-if="disableStep">Disable</span>
        <span v-else-if="regenerateRecoverKey">Regenerate</span>
        <span v-else>Next</span>
      </button>
    </footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import toastMessage from '@/helpers/toastMessage';
import vtulEnums from '../../../../cross/index';

const { managementAction } = vtulEnums.enum.authentication;

export default {
  name: 'OtpManagementModal',
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const dict = {
      custom: {
        otpCode: {
          required: 'The code is required',
          length: 'The code must be of 6 characters',
        },
        password: {
          required: 'The password field is empty',
        },
      },
    };

    return {
      isLoading: false,
      error: undefined,
      manageCurrentOtp: false,
      requestStep: false,
      disableStep: false,
      regenerateRecoverKey: false,
      password: '',
      otpCode: '',
      recoveryKey: '',
      regeneratedRecoveryKey: false,
      otpQrCode: undefined,
      dict,
    };
  },
  created() {
    this.password = '';
    this.regeneratedRecoveryKey = false;
    this.otpQrCode = undefined;
    this.requestStep = !this.user.twoFactorEnabled;
    this.manageCurrentOtp = this.user.twoFactorEnabled;
  },
  mounted() {
    this.$validator.localize('en', this.dict);
  },
  methods: {
    ...mapActions('auth-management', { sendOtpAction: 'create' }),
    ...mapActions('user', { getUser: 'get' }),
    goBack() {
      if (this.disableStep || this.regenerateRecoverKey) {
        this.disableStep = false;
        this.regenerateRecoverKey = false;
        this.manageCurrentOtp = true;
        return;
      }
      this.$parent.close();
    },
    goToDisableOtp() {
      this.manageCurrentOtp = false;
      this.disableStep = true;
    },
    goToRegenerateRecoveryKey() {
      this.manageCurrentOtp = false;
      this.regenerateRecoverKey = true;
    },
    validateBeforeSubmit() {
      if (this.requestStep || this.disableStep || this.regenerateRecoverKey) {
        if (!this.password) {
          this.errors.add({
            field: 'password',
            msg: 'Password field is required',
          });
          return;
        }
        this.errors.remove('password');
        if (this.requestStep) {
          this.requestActivation();
        } else if (this.disableStep) {
          this.disableOtp();
        } else if (this.regenerateRecoverKey) {
          this.regenerateAccountRecoverKey();
        }
      } else if (this.otpQrCode) {
        if (!this.otpCode || this.otpCode.length !== 6) {
          this.errors.add({
            field: 'otpCode',
            msg: this.otpCode ? 'The code must be of 6 characters' : 'The code is required',
          });
          return;
        }
        this.errors.remove('otpCode');
        this.validateCode();
      }
    },
    async requestActivation() {
      this.error = undefined;
      this.isLoading = true;
      try {
        const response = await this.sendOtpAction({
          action: managementAction.REQUEST_2FA.value,
          userId: this.user.id,
          password: this.password,
          strategy: this.$adDomain ? 'activeDirectory' : 'local',
        });
        if (response.qrCodeImage) {
          this.requestStep = false;
          this.otpQrCode = response.qrCodeImage;
          this.password = '';
        } else {
          this.error = {
            message: 'Unexpected error, please try again later',
          };
        }
      } catch (error) {
        this.error = error;
      }
      this.isLoading = false;
    },
    async disableOtp() {
      this.error = undefined;
      this.isLoading = true;
      try {
        const response = await this.sendOtpAction({
          action: managementAction.DISABLE_2FA.value,
          userId: this.user.id,
          password: this.password,
          strategy: this.$adDomain ? 'activeDirectory' : 'local',
        });
        if (response.result) {
          this.getUser(this.user.id); // force reactivity
          toastMessage.showSuccess('Two-factor authentication disabled');
          this.$parent.close();
        } else {
          this.error = {
            message: 'Unexpected error, please try again later',
          };
        }
      } catch (error) {
        this.error = error;
      }
      this.isLoading = false;
    },
    async validateCode() {
      this.error = undefined;
      this.isLoading = true;
      try {
        const response = await this.sendOtpAction({
          action: managementAction.ENABLE_2FA.value,
          userId: this.user.id,
          code: this.otpCode,
        });
        if (response.result) {
          this.getUser(this.user.id); // force reactivity
          this.otpQrCode = null;
          this.recoveryKey = response.recoveryKey;
        } else {
          this.error = {
            message: 'Two-factor code verification failed. Please try again.',
          };
        }
      } catch (error) {
        this.error = error;
      }
      this.isLoading = false;
    },
    async regenerateAccountRecoverKey() {
      this.error = undefined;
      this.isLoading = true;
      try {
        const response = await this.sendOtpAction({
          action: managementAction.REGENERATE_2FA_RECOVERY.value,
          userId: this.user.id,
          password: this.password,
        });
        if (response.result) {
          this.regenerateRecoverKey = false;
          this.regeneratedRecoveryKey = true;
          this.recoveryKey = response.recoveryKey;
        } else {
          this.error = {
            message: 'The current password is not valid',
          };
        }
      } catch (error) {
        this.error = error;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.errorBanner {
  margin:0px;
  padding:8px;
}
</style>
