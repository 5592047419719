<template style="width: auto">
  <form
    action=""
    @submit.prevent="validateBeforeSubmit"
  >
    <card
      internal-card="modal-card card modal-material-card modal-card-form-container"
      url-help=""
    >
      <div
        slot="header"
        class="modal-card-header"
      >
        <p>Change email</p>
      </div>
      <section
        slot="body"
        class="modal-card-body"
      >
        <b-notification
          :active="error != undefined"
          :closable="false"
          class="errorBanner"
          type="is-danger"
        >
          {{ error != undefined ? error.message : '' }}
        </b-notification>
        <br v-show="error != undefined">
        <b-field
          :type="errors.has('newEmail') ? 'is-danger':''"
          label="New email"
        >
          <b-input
            v-model="newEmail"
            v-validate="'required'"
            name="newEmail"
            type="email"
          />
        </b-field>
        <span
          v-show="errors.has('newEmail')"
          class="help is-danger"
        >{{ errors.first('newEmail') }}</span>
        <b-field
          :type="errors.has('password') ? 'is-danger':''"
          label="Password"
        >
          <b-input
            v-model="password"
            v-validate="'required'"
            name="password"
            type="password"
            :password-reveal="password != '' && password != undefined"
          />
        </b-field>
        <span
          v-show="errors.has('password')"
          class="help is-danger"
        >{{ errors.first('password') }}</span>
      </section>
      <footer
        slot="footer"
        class="card-footer has-text-centered modal-card-footer"
      >
        <button
          class="button force-right"
          type="button"
          @click="$parent.close()"
        >
          Cancel
        </button>
        <button
          :disabled="(!password || !newEmail || !differentEmail
            || errors.has('password') || errors.has('newEmail'))"
          :class="{'is-loading': isLoading }"
          class="button is-primary force-right"
          type="submit"
        >
          Change email
        </button>
      </footer>
    </card>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import toastMessage from '@/helpers/toastMessage';
import card from '@/components/cross/Card.vue';
import ErrorMixin from '@/mixins/error';
import vtulEnums from '../../../../cross/index';

const { managementAction } = vtulEnums.enum.authentication;

export default {
  name: 'ChangeUserEmail',
  components: {
    card,
  },
  mixins: [ErrorMixin],
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const dict = {
      custom: {
        newEmail: {
          required: 'The new email field is empty.',
        },
        password: {
          required: 'The password field is empty.',
        },
      },
    };

    return {
      newEmail: undefined,
      password: undefined,
      differentEmail: undefined,
      isLoading: false,
      dict,
      error: undefined,
    };
  },
  watch: {
    newEmail: function differentEmail() {
      if (this.user.email === this.newEmail) {
        this.differentEmail = false;
        this.error = { message: 'The email has to be different from the previous one.' };
      } else {
        this.differentEmail = true;
        this.error = undefined;
      }
    },
  },

  mounted() {
    this.$validator.localize('en', this.dict);
  },
  methods: {
    ...mapActions('auth-management', { sendAuthManagementAction: 'create' }),
    ...mapActions('user', { getUser: 'get' }),
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.changeEmail();
        }
      }).catch((error) => { throw new Error(error); });
    },
    async changeEmail() {
      this.error = undefined;
      this.isLoading = true;
      try {
        const response = await this.sendAuthManagementAction({
          action: managementAction.CHANGE_EMAIL.value,
          userId: this.user.id,
          newEmail: this.newEmail,
          password: this.password,
        });
        if (response.result) {
          this.getUser(this.user.id); // force reactivity
          toastMessage.showSuccess('Email updated sucessfully');
          this.sendVerifyEmailToken();
          this.password = '';
        } else {
          this.error = {
            message: 'Unexpected error, please try again later',
          };
        }
      } catch (error) {
        this.error = error;
      }
      this.isLoading = false;
    },
    async sendVerifyEmailToken() {
      try {
        const response = await this.sendAuthManagementAction({
          action: managementAction.VERIFY_EMAIL.value,
          email: this.newEmail,
        });
        if (response) {
          toastMessage.showSuccess('Check your mailbox to verify your email. If the confirmation email doesn’t appear within a few minutes, check your spam folder.', 8000);
        } else {
          this.error = { message: 'Unexpected error, please try again later' };
        }
      } catch (errorParam) {
        this.error = errorParam.message;
      }
      this.$parent.close();
    },
  },
};
</script>

<style scoped>
.search-input {
  margin: 20px 10px 5px 10px;
}
.action-card-content {
  padding: 2%;
  margin: 4px 6px;
  display: inline-block;
  width: 224px;
  height: 80px;
}
.device-info-title {
  margin-bottom: 0px !important;
  display: inline-flex;
  width: 100%;
  text-align: center;
}
.action-buttons-container {
  position: absolute;
  left: 0;
  bottom: 10%;
  right: 0;
}
.errorBanner {
  margin:0px;
  padding:8px;
}
</style>
