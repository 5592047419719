<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          My profile
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <card
      external-card="material-card-content"
      internal-card="card material-card material-card-content"
      url-help=""
    >
      <section
        slot="body"
      >
        <b-message
          v-if="!user.isEmailVerified"
          type="is-warning"
        >
          To increase the security of your account, confirm your email address.
          <a
            :style="[isLoading && {pointerEvents: 'none'}]"
            @click="resendVerifyEmail"
          >
            Send confirmation email.
          </a>
        </b-message>
        <div class="columns">
          <div
            class="column "
            style="text-align:center"
          >
            <p
              v-show="!editNameActive"
              class="dashboardSubtitle"
              style="margin-bottom: 25px;"
            >
              {{ nameToShow }}
              <a
                style="position:absolute;"
                @click="editName"
              >
                <b-icon
                  style="margin-left:3px;"
                  size="is-small"
                  icon="account-edit"
                />
              </a>
            </p>

            <b-field
              v-show="editNameActive"
              grouped
              position="is-centered"
              class="field-names"
            >
              <b-field
                label="Name"
                style="text-align: left;"
              >
                <b-input
                  v-model="name"
                  placeholder="Given name"
                  size="is-small"
                />
              </b-field>
              <b-field
                label="Last name"
                style="text-align: left;"
              >
                <b-input
                  v-model="lastName"
                  placeholder="Sur-name"
                  size="is-small"
                />
              </b-field>
              <button
                class="button is-primary is-small"
                @click="saveNewName"
              >
                Save
              </button>
            </b-field>

            <div class="force-left">
              <UserImage
                :email="user.email"
                :size="100"
                style="align-text:left"
                :lazy="false"
              />
              <div>
                <strong style="font-size: 13px; color: #4a4a4a; font-weight: normal">
                  Update your profile picture with
                  <a
                    target="_blank"
                    href="https://gravatar.com"
                  >Gravatar</a>
                </strong>
              </div>
              <p>
                {{ user ? user.email : '' }}
                <a
                  v-if="!$adDomain"
                  style="position:absolute;"
                  @click="editEmailActive = true"
                >
                  <b-icon
                    style="margin-left:3px;"
                    size="is-small"
                    icon="email-edit"
                  />
                </a>
              </p>
              <p v-if="!user.isEmailVerified">
                <button
                  class="button is-small is-primary"
                  style="margin-top: 12px"
                  :class="{'is-loading': isLoading }"
                  :disabled="isLoading"
                  @click="resendVerifyEmail"
                >
                  Confirm email
                </button>
              </p>
            </div>
          </div>
          <div class="column">
            <p
              v-show="!editPhoneActive"
              class="profile-data"
            >
              <strong style="margin-right:10px">Phone:</strong>
              <span>{{ user ? user.phoneNumber || '(Not registered)' : '' }}</span>
              <a @click="editPhoneNumber">
                <b-icon
                  style="margin-left:3px;"
                  size="is-small"
                  icon="square-edit-outline"
                /></a>
            </p>
            <b-field v-show="editPhoneActive">
              <strong style="margin-right:10px">Phone: </strong>
              <b-input
                v-model="phoneNumber"
                placeholder="Your phone number"
                name="phoneNumber"
                size="is-small"
                type="phone"
                style="width:100%"
              />
              <button
                class="button is-primary is-small force-right"
                @click="saveNewPhone"
              >
                Save
              </button>
            </b-field>
            <p>
              <strong>User since: </strong>
              <span>
                <timeago
                  v-if="user"
                  :since="user.createdAt"
                  no-future
                  date-without-hours
                />
              </span>
            </p>
            <br>
            <p
              class="profile-subtitle"
            >
              Security
            </p>
            <p class="centerVertically">
              <strong>Two-factor authentication: </strong>
              <span style="margin-left: 4px">
                {{ user && user.twoFactorEnabled ? ' Enabled' : ' Disabled' }}
              </span>
              <a
                style="margin-left: 6px"
                @click="edit2faActive = true"
              >
                <button
                  class="button is-small is-primary"
                >
                  {{ user && user.twoFactorEnabled ? 'Manage' : 'Enable' }}
                </button>
              </a>
            </p>
            <p
              v-if="!$adDomain"
              class="centerVertically"
              style="margin-top:10px;"
            >
              <strong>Password:</strong>
              <a
                style="margin-left: 6px"
                @click="editPasswordActive = true"
              >
                <button
                  class="button is-small is-primary"
                >
                  Change password
                </button>
              </a>
            </p>
          </div>
        </div>
      </section>
      <b-modal
        slot="footer"
        :parent="this"
        :active.sync="editPasswordActive"
        has-modal-card
      >
        <change-user-password :user="user" />
      </b-modal>
      <b-modal
        slot="footer"
        :parent="this"
        :active.sync="editEmailActive"
        has-modal-card
      >
        <change-user-email :user="user" />
      </b-modal>
      <b-modal
        slot="footer"
        :parent="this"
        :active.sync="edit2faActive"
        has-modal-card
        :can-cancel="['escape', 'x']"
      >
        <otp-management :user="user" />
      </b-modal>
    </card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import changeUserPassword from '@/components/users/ChangeUserPassword.vue';
import changeUserEmail from '@/components/users/ChangeUserEmail.vue';
import OtpManagement from '@/components/authentication/OtpManagement.vue';
import card from '@/components/cross/Card.vue';
import userImage from '@/components/cross/UserImage.vue';
import ErrorMixin from '@/mixins/error';
import toastMessage from '@/helpers/toastMessage';
import vtulEnums from '../../../cross/index';

const { programmerUiErrors } = vtulEnums.enum.ui;
const { alertBox } = vtulEnums.enum.alert;
const { managementAction } = vtulEnums.enum.authentication;

export default {
  name: 'User',
  metaInfo: {
    title: 'My profile',
  },
  components: {
    'change-user-password': changeUserPassword,
    'change-user-email': changeUserEmail,
    'otp-management': OtpManagement,
    card,
    UserImage: userImage,
  },
  mixins: [ErrorMixin],
  data() {
    return {
      isLoading: false,
      name: '',
      lastName: '',
      phoneNumber: '',
      editNameActive: false,
      editPasswordActive: false,
      editEmailActive: false,
      editPhoneActive: false,
      edit2faActive: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    nameToShow() {
      if (!this.user.name && !this.user.lastName) return '(Name and last name not registered)';
      return `${this.user.name || ''} ${this.user.lastName || ''}`;
    },
  },
  methods: {
    ...mapActions('user', { patchUser: 'patch' }),
    ...mapActions('user', { getUser: 'get' }),
    ...mapActions('auth-management', { sendVerifyToken: 'create' }),
    editName() {
      this.name = this.user.name;
      this.lastName = this.user.lastName;
      this.editNameActive = true;
    },
    editPhoneNumber() {
      this.phoneNumber = this.user.phoneNumber;
      this.editPhoneActive = true;
    },
    saveNewName() {
      this.editNameActive = false;
      if (!this.name && !this.lastName) return;
      if (this.name === this.user.name && this.lastName === this.user.lastName) return;

      const params = {
        name: this.name ? this.capitalizeFirstLeter(this.name) : undefined,
        lastName: this.lastName ? this.capitalizeFirstLeter(this.lastName) : undefined,
      };
      this.patchUser([this.user.id, { ...params }])
        .catch((err) => {
          this.throwVtulError(programmerUiErrors.UPDATE_NAME_ERROR, alertBox.SNACKBAR.value, err);
        });
    },
    capitalizeFirstLeter(text) {
      const splitedText = text.split(' ');
      let finalText = '';
      splitedText.forEach((currentText) => {
        finalText += `${currentText.charAt(0).toUpperCase()}${currentText.slice(1)} `;
      });
      return finalText.trim();
    },
    saveNewPhone() {
      this.editPhoneActive = false;
      if (this.phoneNumber && this.phoneNumber !== this.user.phoneNumber) {
        this.patchUser([this.user.id, { phoneNumber: this.phoneNumber }]).catch((err) => {
          this.throwVtulError(programmerUiErrors.UPDATE_PHONE_ERROR,
            alertBox.SNACKBAR.value, err);
        });
      }
    },
    async resendVerifyEmail() {
      let tokenCreatedAt = this.user.emailVerificationCreated || moment().subtract(10, 'minutes');

      if (moment().diff(tokenCreatedAt, 'minutes') >= 10) {
        this.isLoading = true;
        try {
          const response = await this.sendVerifyToken({
            action: managementAction.VERIFY_EMAIL.value,
            email: this.user.email,
          });
          if (response) {
            await this.getUser(this.user.id); // force reactivity
            toastMessage.showSuccess('Check your mailbox to verify your email. If the confirmation email doesn’t appear within a few minutes, check your spam folder.', 8000);
          } else {
            this.error = { message: 'Unexpected error, please try again later' };
          }
        } catch (errorParam) {
          this.error = errorParam.message;
        }
        this.isLoading = false;
      } else {
        tokenCreatedAt = moment(tokenCreatedAt).add(10, 'minutes');
        toastMessage.showError(`You can send another confirmation email ${moment(tokenCreatedAt).fromNow()}`);
      }
    },
  },
};
</script>

<style scoped>
.profile-picture-content {
  text-align: center;
}
.profile-picture-content h1 {
  font-size: 22px;
  font-weight: 500;
  margin-top: 6px;
}
.profile-picture-content p {
  font-weight: 100;
}
.profile-picture-list {
  width: 50px;
}
.divider {
  background-color: #632d8e;
}
.profile-data {
  display: flex;
}
.user-card {
  margin: 1.3%;
}
.user-card-content {
  display: flex;
  padding: 10px 10px 5px 10px;
}

.material-card {
  margin: 0 auto;
}
.profile-subtitle {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}

</style>

<style>
.field-names .field{
  align-items: end !important
}
</style>
